<script>
import { ref } from 'vue'
import { useDeviceStore } from '@/stores/device'
import TieredMenu from 'primevue/tieredmenu'
import FocusBar from '@/components/layout/header/FocusBar.vue'
import ObjectManipulator from '@/components/mixins/ObjectManipulator'
import InvoicePDFMixin from '@/components/invoice/InvoicePDFMixin.js'
import eventBus from '@/eventBus'

export default {
  name: 'InvoiceHeader',
  components: { FocusBar, TieredMenu },
  data() {
    return {
      hasAccessToExporter: false,
      invoice: null
    }
  },
  mixins: [ObjectManipulator('invoice', true, 'Invoice'), InvoicePDFMixin],
  async created() {
    const { payload } = await this.$store.dispatch('ExportToken/hasAccess', {
      type: 'quickbooks'
    })
    const { value: verification } = payload
    this.hasAccessToExporter = verification
  },
  computed: {
    invoiceActions() {
      const actions = [
        {
          icon: 'fa fa-cloud-arrow-down',
          class: '!text-level-yellow',
          command: this.download,
          label: 'Download'
        }
      ]
      if (this.hasAccessToExporter) {
        actions.splice(1, 0, {
          icon: 'fa fa-sync',
          class: '!text-level-yellow',
          command: this.syncInvoice,
          label: 'Sync with QuickBooks'
        })
      }
      return actions
    }
  },
  methods: {
    download() {
      this.savePDF()
    },
    async saveInvoice() {
      return this.save()
    },
    sendInvoice() {
      return this.$store.dispatch('Invoice/send', { refId: this.refId })
    }
  },
  mounted() {
    eventBus.$on(`${this.uid}-selected`, () => {
      this.invoice = this.norm[this.refId]
      this.renderDoc()
    })
  },
  beforeUnmount() {
    eventBus.$off(`${this.uid}-selected`)
  },
  setup() {
    const deviceStore = useDeviceStore()
    const refToggleButton = ref(null)
    const menu = ref()
    const toggle = () => {
      menu.value.toggle({ currentTarget: refToggleButton.value.$el })
    }

    return {
      deviceStore,
      refToggleButton,
      menu,
      toggle
    }
  }
}
</script>

<template>
  <FocusBar :save="saveInvoice">
    <template #middle>
      <div class="justify-center items-center hidden md:flex">
        <div v-if="invoice_id" class="text-xl">Invoice #{{ invoice_id }}</div>
        <div v-else>New invoice</div>
      </div>
    </template>
    <template v-if="invoice_id" #right>
      <div>
        <Btn
          ref="refToggleButton"
          class="rounded-r-none"
          size="lg"
          severity="primary-black"
          :action="sendInvoice"
        >
          <template #icon>
            <font-awesome-icon icon="paper-plane" />
          </template>
          Send
        </Btn>
        <Btn
          ref="refToggleButton"
          :class="[!deviceStore.isMobile ? 'rounded-l-none' : '']"
          @click="toggle"
          severity="primary-black"
          size="lg"
        >
          <font-awesome-icon :icon="deviceStore.isMobile ? 'ellipsis' : 'chevron-down'" />
        </Btn>
        <TieredMenu ref="menu" id="overlay_tmenu" :model="invoiceActions" popup />
      </div>
    </template>
  </FocusBar>
</template>

<style scoped lang="scss"></style>
